import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Profil', url: 'profil', icon: 'person' },
    { title: 'Haushaltsgruppe', url: 'homescreen', icon: 'home' },
    { title: 'Einnahmen/Ausgaben', url: 'einaus', icon: 'bar-chart' },
    { title: 'Sparziele', url: 'sparziel', icon: 'airplane' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  benutzername: any;
  benutzerID: any;

  constructor(private router: Router, public menuCtrl: MenuController){
    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.loadMenu();
      }
    });
  }
  loadMenu() {
    this.benutzername = sessionStorage.getItem('benutzername');
    this.benutzerID = sessionStorage.getItem('benutzerID');
  }

  disMenu()
  {
    this.menuCtrl.enable(false);
  }
}
