import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { h } from 'ionicons/dist/types/stencil-public-runtime';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'haushaltsgruppe',
    loadChildren: () => import('./pages/haushaltsgruppe/haushaltsgruppe.module').then( m => m.HaushaltsgruppePageModule)
  },
  {
    path: 'einaus',
    loadChildren: () => import('./pages/einaus/einaus.module').then( m => m.EinausPageModule)
  },
  {
    path: 'sparziel',
    loadChildren: () => import('./pages/sparziel/sparziel.module').then( m => m.SparzielPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'registrieren',
    loadChildren: () => import('./pages/registrieren/registrieren.module').then( m => m.RegistrierenPageModule)
  },
  {
    path: 'homescreen',
    loadChildren: () => import('./pages/homescreen/homescreen.module').then( m => m.HomescreenPageModule)
  },
  {
    path: 'code-page',
    loadChildren: () => import('./pages/code-page/code-page.module').then( m => m.CodePagePageModule)
  },
  {
    path: 'gruppe-erstellen',
    loadChildren: () => import('./pages/gruppe-erstellen/gruppe-erstellen.module').then( m => m.GruppeErstellenPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'einnahme',
    loadChildren: () => import('./pages/einnahme/einnahme.module').then( m => m.EinnahmePageModule)
  },
  {
    path: 'sparziel-editor',
    loadChildren: () => import('./pages/sparziel-editor/sparziel-editor.module').then( m => m.SparzielEditorPageModule)
  },
  {
    path: 'spardetails/:id',
    loadChildren: () => import('./pages/spardetails/spardetails.module').then( m => m.SpardetailsPageModule)
  },
  {
    path: 'einausdetails/:id',
    loadChildren: () => import('./pages/einausdetails/einausdetails.module').then( m => m.EinausdetailsPageModule)
  },
  {
    path: 'einnahmegruppe',
    loadChildren: () => import('./pages/einnahmegruppe/einnahmegruppe.module').then( m => m.EinnahmegruppePageModule)
  },
  {
    path: 'sparzielgruppe-editor',
    loadChildren: () => import('./pages/sparzielgruppe-editor/sparzielgruppe-editor.module').then( m => m.SparzielgruppeEditorPageModule)
  },
  {
    path: 'einaus-bearbeiten',
    loadChildren: () => import('./pages/einaus-bearbeiten/einaus-bearbeiten.module').then( m => m.EinausBearbeitenPageModule)
  },
  {
    path: 'sparziel-bearbeiten',
    loadChildren: () => import('./pages/sparziel-bearbeiten/sparziel-bearbeiten.module').then( m => m.SparzielBearbeitenPageModule)
  },
  {
    path: 'sparziel-umbuchen',
    loadChildren: () => import('./pages/sparziel-umbuchen/sparziel-umbuchen.module').then( m => m.SparzielUmbuchenPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
